<template>
  <div class="teamText-section">
    <div
      class="project-section py-16 lg:px-24 md:px-8"
      data-aos="fade-up" data-aos-offset="200" data-aos-delay="30" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="false" data-aos-once="true" data-aos-anchor-placement="top-bottom"
    >
      <div class="work-project flex justify-between">
        <div class="team-title w-1/2">
          <div class="titre text-5xl font-extrabold text-black mb-8">
            {{ $t('weValue') }}
          </div>
          <div class="titre-desc w-2/3 leading-normal">
            <div class="text-xl font-semibold mb-12">
              {{ $t('promptAndResponsive') }}
            </div>
            <div class="text-xl text-black font-hairline">
              {{ $t('imagineAMoment') }}
            </div>
          </div>
        </div>
        <div class="team-konva w-1/2 flex">
          <img :src="images.process" alt="app" class="p-2 pl-6 pr-5 xl:pl-16 xl:pr-20 lg:w-609px " original>
        </div>
      </div>
    </div>
    <div
      class="text-intro py-20 leading-normal lg:px-24 md:px-8"
    >
      <div
        data-aos="fade-up" data-aos-offset="200" data-aos-delay="30" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="false" data-aos-once="true" data-aos-anchor-placement="top-bottom"
      >
        <p class="begin text-5xl font-extrabold">
          {{ $t('customer') }}
        </p>
        <p class="begin text-5xl font-extrabold">
          {{ $t('teamThat') }}
        </p>
        <p class="begin text-5xl font-extrabold">
          {{ $t('understand') }}
        </p>
      </div>
    </div>
    
    <div class="team-info mt-8 mb-24 lg:px-24 md:px-8">
      <div class="work-project flex justify-between mb-8">
        <team-detail
          v-for="(item, key) in team"
          :key="item + key"
          :teams="item"
          class="flex-1"
        />
      </div>
      <div class="work-project flex justify-between">
        <team-detail
          v-for="(item, key) in team1"
          :key="item + key"
          :teams="item"
          class="flex-1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TeamDetail from './team-detail'
import process from '../../../assets/images/webp/img_girl.webp'
import profil from '../../../assets/images/g.jpg'
import profil1 from '../../../assets/images/g.jpg'
import profil2 from '../../../assets/images/g.jpg'
import profil3 from '../../../assets/images/g.jpg'
import profil4 from '../../../assets/images/g.jpg'
import profil5 from '../../../assets/images/f.png'
import profil6 from '../../../assets/images/h.png'

export default {
  name: 'team-text',
  components: { TeamDetail },
  data () {
    return {
      images: { process },
      configKonva: {
        width: 200,
        height: 200
      },
      configCircle: {
        x: 100,
        y: 100,
        radius: 90,
        fill: '#5F01D3',
        opacity: 0.2,
        stroke: '#5F01D3',
        strokeWidth: 4
      },
      team: [
      {
          picture: profil3,
          name: 'Romaric Tchokpon',
          poste: 'CEO',
          urlFacebook: '',
          urlLinkedin: 'https://www.linkedin.com/in/tromaric/',
          urlTwitter: ''
        },
        {
          picture: profil5,
          name: 'Fatoumata Coulibaly',
          poste: 'Chef de Projet',
          urlFacebook: '',
          urlLinkedin: '',
          urlTwitter: ''
        },
        {
          picture: profil2,
          name: 'AGUEMON Segla Rodolphe',
          poste: 'Responsable Communication',
          urlFacebook: '',
          urlLinkedin: 'https://www.linkedin.com/in/rodolphe229/',
          urlTwitter: ''
        },
        {
          picture: profil,
          name: 'Mahulidji Denis HOUESSOU',
          poste: 'Assistant Chef Projet BPM',
          urlFacebook: '',
          urlLinkedin: 'https://www.linkedin.com/in/mahulidji-denis-houessou-08b5a2233/',
          urlTwitter: ''
        },
      ],
      team1: [
        {
          picture: profil4,
          name: 'Razack Bouko',
          poste: 'Tech Lead',
          urlFacebook: '',
          urlLinkedin: 'https://www.linkedin.com/in/razack-bouko-82500a190/',
          urlTwitter: ''
        },
        {
          picture: profil1,
          name: 'Abdoulaye Diarra',
          poste: 'Chef Process Automation ',
          urlFacebook: '',
          urlLinkedin: 'https://www.linkedin.com/in/abdoulaye-diarra-4459a8176/',
          urlTwitter: ''
        },
        {
          picture: profil6,
          name: 'Nous Recherchons notre prochain Talent',
          poste: 'Business Developer ',
          urlFacebook: '',
          urlLinkedin: 'https://www.linkedin.com/in/abdoulaye-diarra-4459a8176/',
          urlTwitter: ''
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .text-intro {
    background-color: #023C61;
    color: $white;
    text-align: center;
  }
  .cercle1 {
    top: 48rem !important;
    left: 840px !important;
  }
  .cercle2 {
    top: 48rem !important;
    left: 1000px !important;
  }
  .cercle3 {
    top: 11.5rem;
  }
  @media screen and (max-width: 600px) {
    .text-intro, .project-section {
      padding-left: 1rem;
      padding-right: 1rem;
      font-size: 3rem !important;
    }
    .begin {
      font-size: 2.25rem; /* 36px */
      line-height: 2.5rem; /* 40px */     
      display: inline;
    }
    .work-project {
      display: block;
    }
    .team-title, .titre-desc {
      width: 100%;
    }
    .titre-desc {
      div {
        font-size: 1.5rem;
      }
    }
    .team-konva {
      display: none;
    }
    .titre {
    }
  }
  @media screen and (min-width: 600px) and (max-width: 767px) {
    .text-intro, .project-section {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .begin {
      font-size: 4rem;
      display: inline;
    }
    .work-project {
      display: block;
    }
    .team-title, .titre-desc {
      width: 100%;
    }
    .titre-desc {
      div {
        font-size: 1.5rem;
      }
    }
    .team-konva {
      display: none;
    }
    .titre {
      font-size: 4rem;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .begin {
      display: inline;
    }
    .team-konva {
      display: none;
    }
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    .cercle1 {
      top: 48rem;
      left: 43rem;
    }
    .cercle2 {
      top: 48rem;
      left: 55rem;
    }
    .cercle3 {
      top: 11.5rem;
    }
  }
</style>
