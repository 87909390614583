<template>
  <div
    class="project-details p-4"
    data-aos="fade-up" data-aos-offset="200" data-aos-delay="30" data-aos-duration="500" data-aos-easing="ease-in-out" data-aos-mirror="false" data-aos-once="true" data-aos-anchor-placement="top-bottom"
  >
    <div class="picture mb-1">
      <img
        :src="teams.picture"
        class="image"
      >
    </div>
    <div class="information">
      <div class="name text-lg text-black font-semibold">
        {{ teams.name }}
      </div>
    </div>
    <div class="description text-lg font-semibold leading-normal">
      {{ teams.poste }}
    </div>
    <div
      class="btnMore cursor-pointer flex"
    >
      <div
        class="facebook cursor-pointer"
        @click="goToFacebook"
      >
        <svg-icon
          name="ic_facebook"
          class="w-12 icon"
          original
        />
      </div>
      <div
        class="linkedin cursor-pointer"
        @click="goToLinkedIn"
      >
        <svg-icon
          name="ic_linkedin"
          class="w-12 icon"
          original
        />
      </div>
      <div
        class="twitter cursor-pointer"
        @click="goToTwitter"
      >
        <svg-icon
          name="ic_twitter"
          class="w-12 icon"
          original
        />
      </div>
    </div>
  </div>
</template>

<script>
import profil from '../../../assets/images/zazapro.jpeg'
export default {
  name: 'team-detail',
  props: {
    teams: {
      type: Object,
      default: function () {
        return {
          picture: profil,
          name: 'John DOE',
          poste: 'Business Expert',
          urlFacebook: '',
          urlLinkedin: '',
          urlTwitter: ''
        }
      }
    }
  },
  methods: {
    goToFacebook () {
      window.open(this.teams.urlFacebook, '_blank')
    },
    goToLinkedIn () {
      window.open(this.teams.urlLinkedin, '_blank')
    },
    goToTwitter () {
      window.open(this.teams.urlTwitter, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .description {
    color: #023C61;
  }
  .icon {
    fill: $white;
  }
  .image {
    height: 20rem;
  }
  @media screen and (max-width: 600px) {
    .image {
      width: 100%;
      height: 100%;
    }
    .information, .description, .btnMore {
      text-align: center;
      justify-content: center;
    }
    .name, .description {
      font-size: 1.5rem;
    }
    .icon {
      width: 4rem;
    }
    .picture{
      margin-left: 8rem;
    }
  }
  @media screen and (min-width: 600px) and (max-width: 767px) {
    .image {
      width: 100%;
      height: 100%;
    }
    .information, .description, .btnMore {
      text-align: center;
      justify-content: center;
    }
    .name, .description {
      font-size: 1.5rem;
    }
    .icon {
      width: 4rem;
    }
  }
</style>
