<template>
    <div class="team-page">
      <team-text />
      <div class="footer-section">
      </div>
    </div>
  </template>

<script>
import TeamText from '../../components/helpers/team/team-text'
export default {
  name: 'team',
  components: { TeamText},
  created: function () {
    document.title = 'BforProcess | Tech Process Company'
    document.querySelector('meta[name = "description"]').setAttribute('content', 'Tech Company')
  }
}
</script>

  <style lang="scss" scoped>
@import "../../assets/style/sass/variables";
  </style>
